import { updateVar } from "./popups.js"

const pageHideHandler = () => {
    const popupFadeVisible = $('#popupFade').is(':visible');
    const popupLoaderVisible = $('#popupLoader').is(':visible') || $('#popupLoaderAdv').is(':visible');

    if (popupFadeVisible) {
        $('#popupFade').hide();
        updateVar('allow', true);
    }

    if (popupLoaderVisible) {
        $('#popupLoader, #popupLoaderAdv').hide();
        updateVar('allow', true);
    }
};

const init = () => {
    window.addEventListener("pagehide", pageHideHandler);
}

export default init;
